import React, { useState } from 'react';
import { Typography, Box, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import axiosInstance from '../../../utils/axios';
import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';
import { RHFToggleButtonGroup } from '../../../components/hook-form';

const GenerateReportModal = ({ client, open, onClose, onUserUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Use React Hook Form for form handling
  const methods = useForm({
    defaultValues: { reportType: 'EXCEL' },
  });

  const { handleSubmit } = methods;

  const handleReportGeneration = async (data) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `/trade/generate-trade-report/${client._id}?reportType=${data.reportType}`
      );
      enqueueSnackbar(response.data.message, { variant: 'success' });
      setIsLoading(false);
      onUserUpdate();
      onClose();
    } catch (err) {
      setIsLoading(false);
      onClose();
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Wrapper
      open={open}
      onClose={onClose}
      children={
        <Box width={{ xs: '100%', sm: '350px' }} marginX={'auto'}>
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" textAlign="center" pb={2}>
            {`Generate Report`}
          </Typography>
          <Typography variant="body1" textAlign="center" pb={2}>
            Please select the report type
          </Typography>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleReportGeneration)}>
              <RHFToggleButtonGroup
                name="reportType"
                label="Select Report Type"
                sx={{ width: '100%', mb: 2 }}
                options={[
                  { label: 'EXCEL', value: 'EXCEL' },
                  { label: 'PDF', value: 'PDF' },
                ]}
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    size="large"
                    type="submit"
                    color="primary"
                    loading={isLoading}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" fullWidth size="large" onClick={onClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      }
    />
  );
};

GenerateReportModal.propTypes = {
  client: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUserUpdate: PropTypes.func,
};

export default GenerateReportModal;
