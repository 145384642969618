/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Grid, IconButton, Box, Tabs, Tab, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { AddOutlined, FileDownloadDoneOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import Iconify from '../../../../components/Iconify';
import useTrade from '../../../../hooks/useTrade';
import Page from '../../../../components/Page';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useAuth from '../../../../hooks/useAuth';
// eslint-disable-next-line import/no-named-as-default
import WatchListItem from './WatchListItem';
import StaticWatchListItem from './StaticWatchListItem';
import { dispatch } from '../../../../redux/store';
import {
  addToWatchListSuccess,
  getUserPortfolio,
  getWatchList,
  getWatchlistSuccess,
} from '../../../../redux/slices/trades';

const LOCAL_STORAGE_KEY = 'selectedTab'; // Define a key to store the selected tab

const WatchlistRow = () => {
  const [searchText, setSearchText] = useState('');
  const { user } = useAuth();
  const segments = ['MCX', 'NSE', 'COMEX', 'CRYTPO', 'FOREX'];
  const userSegments = user ? segments.filter((segment) => user[segment]?.allow) : [];

  // Get initial tab from local storage or default to the first segment
  const getInitialTab = () => {
    const storedTab = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedTab && userSegments.includes(storedTab) ? storedTab : userSegments[0];
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab());
  const [showDelete, setShowDelete] = useState(false);
  const { quotesData, watchlist, userTrades } = useSelector((state) => state?.trade);
  const { localSocket } = useTrade();
  const navigate = useNavigate();

  // Fetch watchlist data from local storage or API
  useEffect(() => {
    dispatch(getWatchList());
    if (user.role.rolename === 'client') {
      dispatch(getUserPortfolio());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Sync selectedTab to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (userTrades?.OPEN && selectedTab) {
      const openTradesSymbols = userTrades.OPEN.filter((trade) => trade.script.exchange.name === selectedTab);
      const currentWatchlistScripts = watchlist && watchlist[selectedTab] ? Object.values(watchlist[selectedTab]) : [];
      const newScripts = openTradesSymbols
        .filter(
          (trade) => !currentWatchlistScripts.some((script) => script.tradingsymbol === trade.script.tradingsymbol)
        )
        .map((trade) => trade.script);

      if (newScripts.length > 0) {
        const updatedWatchlist = {
          ...watchlist,
          [selectedTab]: [...currentWatchlistScripts, ...newScripts],
        };
        const isUpdated = JSON.stringify(currentWatchlistScripts) !== JSON.stringify(updatedWatchlist[selectedTab]);

        if (isUpdated) {
          dispatch(getWatchlistSuccess(updatedWatchlist));
          localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
        }
      }
    }
  }, [userTrades?.OPEN, selectedTab, watchlist]);

  useEffect(() => {
    const tokenArray =
      watchlist && watchlist[selectedTab]
        ? Object.values(watchlist[selectedTab]).map((script) => +script.instrument_token)
        : [];

    if (tokenArray.length) {
      const payload = tokenArray.map((token) => +token);

      const sendSubscribeMessage = () => {
        localSocket.send(JSON.stringify({ event: 'subscribe', data: payload }));
      };

      if (localSocket) {
        if (localSocket.readyState === 1) {
          setTimeout(() => {
            sendSubscribeMessage();
          }, 100);
        }
        localSocket.onopen = () => {
          setTimeout(() => {
            sendSubscribeMessage();
          }, 100);
        };
      }
    }

    return () => {
      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        if (tokenArray.length > 0) {
          localSocket.send(JSON.stringify({ event: 'unsubscribe', data: tokenArray.map((token) => +token) }));
        }
      }
    };
  }, [watchlist, selectedTab, localSocket?.readyState, localSocket]);

  useEffect(() => {
    const currentDate = new Date();

    const removeExpiredScripts = () => {
      if (watchlist && selectedTab && watchlist[selectedTab]) {
        const updatedWatchlist = { ...watchlist };

        updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((script) => {
          const expiryDate = new Date(script.squareoff_date);
          return expiryDate >= currentDate;
        });

        // Check if any scripts were removed
        if (updatedWatchlist[selectedTab].length !== watchlist[selectedTab].length) {
          localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
          dispatch(getWatchlistSuccess(updatedWatchlist));
        }
      }
    };

    removeExpiredScripts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchlist, selectedTab, dispatch]);

  const filteredItems =
    watchlist && watchlist[selectedTab]
      ? Object.values(watchlist[selectedTab])
          .map((script) => {
            const quoteData = quotesData[script.instrument_token] || {};
            return { ...script, ...quoteData };
          })
          .filter((script) => script.name.toLowerCase().includes(searchText.toLowerCase()))
      : [];

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangeTab = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleWatchListClick = () => {
    if (user.role.rolename === 'client') {
      navigate(PATH_DASHBOARD.bankerRole.watchlist.add);
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.watchlist.add);
    }
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.watchlist.add);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const items = [...filteredItems];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedWatchlist = { ...watchlist, [selectedTab]: items };
    localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
    dispatch(getWatchlistSuccess(updatedWatchlist));
  };

  const handleDeleteItem = useCallback(
    (itemId) => {
      const updatedWatchlist = { ...watchlist };
      updatedWatchlist[selectedTab] = updatedWatchlist[selectedTab].filter((item) => item._id !== itemId);
      localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
      dispatch(addToWatchListSuccess(updatedWatchlist));
    },
    [selectedTab, watchlist]
  );

  const handleEditChange = () => {
    setShowDelete((prev) => !prev);
  };

  return (
    <Page title={'Watchlist'} sx={{ px: 2 }}>
      <Grid container spacing={2}>
        {user.role.rolename === 'client' ? (<Box sx={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'background.paper', width: '100%' }}>
          <Grid container spacing={2} sx={{ color: 'blue' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                pt: 2,
              }}
            >
              {!showDelete && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton sx={{ padding: 0, mx: 3.2, color: 'blue' }} onClick={handleWatchListClick}>
                    <AddOutlined icon={'eva:plus-circle-outline'} sx={{ fontSize: '40px' }} />
                  </IconButton>
                </Box>
              )}

              {/* Centered Watchlist Heading using Box */}
              {!showDelete ? (
                <Box sx={{ flexGrow: 2, textAlign: 'center', fontWeight: 'bold', fontSize: '16px', color: 'black' }}>
                  WATCHLIST
                </Box>
              ) : (
                <Box
                  sx={{
                    marginLeft: 10,
                    flexGrow: 2,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    color: 'black',
                  }}
                >
                  WATCHLIST
                </Box>
              )}

              {watchlist && !!Object.keys(watchlist).length && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                  <IconButton sx={{ padding: 0, mx: 1, color: 'blue' }} onClick={handleEditChange}>
                    {showDelete ? (
                      <FileDownloadDoneOutlined sx={{ fontSize: '33px' }} />
                    ) : (
                      <ModeEditOutlineOutlined sx={{ fontSize: '33px' }} />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>

            <Grid item xs={12} sx={{ px: 2}}>
              {' '}
              {/* Adds padding on both sides */}
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="watchlist tabs"
                sx={{
                  width: '100%',
                  display: 'flex',
                  maxWidth: '100%',
                  mx: 'auto',
                  px: 1,
                  marginLeft: 1,
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'black', 
                  },
                }}
              >
                {userSegments.map((exchange) => (
                  <Tab
                    label={exchange}
                    value={exchange}
                    key={exchange}
                    sx={{
                      flexGrow: 1,
                      maxWidth: 'none',
                      color: 'black', // Default color for the tab text
                      '&.Mui-selected': {
                        color: 'black', // Color when the tab is selected
                        fontWeight: 'bold', // Optional: Bold the selected tab text
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Grid>

            <Grid item xs={12} sx={{ px: 2, marginLeft: 3 }}>
              <TextField
                fullWidth
                label="Search Symbols"
                value={searchText}
                onChange={handleSearchChange}
                sx={{
                  // Update text color
                  '& .MuiInputBase-input': {
                    color: 'black', // Text color inside the input
                  },
                  // Update label color
                  '& .MuiInputLabel-root': {
                    color: 'black', // Label color
                  },
                  // Update label color when focused
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'black', // Label color when focused
                  },
                  // Update border color
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black', // Default border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'black', // Border color when hovered
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'black', // Border color when focused
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>): (<Box sx={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'background.paper', width: '100%' }}>
          <Grid container spacing={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                pt: 2,
              }}
            >
              {!showDelete && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton color="primary" sx={{ padding: 0, mx: 1 }} onClick={handleWatchListClick}>
                    <Iconify icon={'eva:plus-circle-outline'} sx={{ fontSize: '40px' }} />
                  </IconButton>
                </Box>
              )}

              {watchlist && !!Object.keys(watchlist).length && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                  <Button variant="contained" onClick={handleEditChange}>
                    {showDelete ? 'DONE' : 'EDIT'}
                  </Button>
                </Box>
              )}
            </Box>
            <Grid item xs={12}>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="watchlist tabs"
                sx={{ width: '100%', display: 'flex' }}
              >
                {userSegments.map((exchange) => (
                  <Tab label={exchange} value={exchange} key={exchange} sx={{ flexGrow: 1, maxWidth: 'none' }} />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Search Symbols" value={searchText} onChange={handleSearchChange} />
            </Grid>
          </Grid>
        </Box>)}
        <Grid item xs={12}>
          <SwipeableViews
            index={userSegments.indexOf(selectedTab)}
            onChangeIndex={(index) => setSelectedTab(userSegments[index])}
          >
            {userSegments.map((exchange) => (
              <Box key={exchange}>
                {showDelete ? (
                  <WatchListItem
                    filteredItems={filteredItems}
                    handleDeleteItem={handleDeleteItem}
                    handleDragEnd={handleDragEnd}
                    showDelete={showDelete}
                    user={user}
                    selectedTab={selectedTab}
                  />
                ) : (
                  <StaticWatchListItem
                    filteredItems={filteredItems}
                    handleDeleteItem={handleDeleteItem}
                    handleDragEnd={handleDragEnd}
                    showDelete={showDelete}
                    user={user}
                  />
                )}
              </Box>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
    </Page>
  );
};

export default WatchlistRow;
