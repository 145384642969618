/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography, Box, Grid, AppBar } from '@mui/material';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import Page from '../../../../components/Page';
import { dispatch } from '../../../../redux/store';
import { getUserPortfolio } from '../../../../redux/slices/trades';
import TradeCard from './TradeCard';
import useTrade from '../../../../hooks/useTrade';
import PortfolioHeader from './PortFolioHeader';
import TradesRow from '../trades/TradesRow';

// Reusable TabPanel component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ height: '100%', overflowY: 'auto' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function Portfolio() {
  const { userTrades, quotesData, balance, bookedPl, blockedMargin, brokerage, priceChangeConfig, showAccountDetails } =
    useSelector((state) => state?.trade);

  const { localSocket } = useTrade();
  const [tabValue, setTabValue] = useState(0); // Use index 0 for "ACTIVE" and 1 for "ORDERS"

  // Extract and map trades
  const activeTrades = userTrades?.OPEN
    ? userTrades.OPEN.map((trade) => ({
        ...trade,
        script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
      }))
    : [];

  const orderTrades = userTrades?.ORDER
    ? userTrades.ORDER.map((trade) => ({
        ...trade,
        script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
      }))
    : [];

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getUserPortfolio());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, []);

  // WebSocket subscription logic
  useEffect(() => {
    const tokenArray =
      userTrades && Object.keys(userTrades).length > 0
        ? [
            ...userTrades?.OPEN?.map((trade) => trade?.script?.instrument_token),
            ...userTrades?.PENDING?.map((trade) => trade?.script?.instrument_token),
            ...userTrades?.ORDER?.map((trade) => trade?.script?.instrument_token),
          ]
        : [];
    if (tokenArray.length) {
      const payload = tokenArray.map((token) => +token);

      const sendSubscribeMessage = () => {
        console.log('Sending subscribe message:', payload);
        localSocket.send(JSON.stringify({ event: 'subscribe', data: payload }));
      };

      if (localSocket) {
        if (localSocket.readyState === WebSocket.OPEN) {
          sendSubscribeMessage();
        } else {
          localSocket.onopen = () => {
            console.log('WebSocket connection opened');
            sendSubscribeMessage();
          };
        }
      } else {
        console.log('localSocket is not defined');
      }
    }

    return () => {
      if (localSocket && localSocket.readyState === WebSocket.OPEN) {
        if (tokenArray.length > 0) {
          console.log('Sending unsubscribe message:', tokenArray);
          localSocket.send(JSON.stringify({ event: 'unsubscribe', data: tokenArray.map((token) => +token) }));
        }
      }
    };
  }, [userTrades, localSocket, localSocket?.readyState]);

  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Handle swipe index change
  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <Page sx={{ px: 2 }}>
      <Box mt={-1}>
        <PortfolioHeader
          balance={balance}
          bookedPL={bookedPl}
          blockedMargin={blockedMargin}
          brokerage={brokerage}
          activeTrades={activeTrades}
          priceChangeConfig={priceChangeConfig}
          showAccountDetails={showAccountDetails}
        />
        <Box sx={{ width: '100%', flexGrow: 1 }}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ width: '100%', display: 'flex' }}
            >
              <Tab label="NET ACTIVE" value={0} sx={{ flexGrow: 1, maxWidth: 'none' }} />
              <Tab label="NET CLOSED" value={1} sx={{ flexGrow: 1, maxWidth: 'none' }} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            index={tabValue}
            onChangeIndex={handleSwipeChangeIndex}
            style={{ minHeight: 'calc(100vh - 128px)' }}
          >
            <TabPanel value={tabValue} index={0}>
              <Grid container>
                {activeTrades.length > 0 ? (
                  activeTrades.map((trade) => (
                    <Grid item xs={12} key={trade?.script?._id}>
                      <TradeCard
                        trade={{
                          ...trade,
                          script: { ...trade.script, ...quotesData[trade?.script?.instrument_token] },
                          type: 'OPEN',
                        }}
                        closeFromPortfolio
                        priceChangeConfig={priceChangeConfig}
                        allowQuantityModification
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    mt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      paddingTop: 16,
                      ml: { xs: 13, sm: 90 },
                    }}
                  >
                    NO ACTIVE TRADES
                  </Typography>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={2}>
                {orderTrades.length > 0 ? (
                  orderTrades.map((trade) => (
                    <Grid item xs={12} key={trade?.script?._id}>
                      <TradesRow trade={trade} key={trade?.script?._id} />
                    </Grid>
                  ))
                ) : (
                  <Typography
                    variant="h6"
                    mt={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      paddingTop: 16,
                      ml: { xs: 13, sm: 90 },
                    }}
                  >
                    NO CLOSED TRADES
                  </Typography>
                )}
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
    </Page>
  );
}

export default Portfolio;
