import { Dashboard, DateRange, Summarize, Troubleshoot } from '@mui/icons-material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  weekelyReports: <DateRange />,
  riskReport: <Troubleshoot />,
  analytics: getIcon('ic_analytics'),
  dashboard:<Dashboard/>,
  menuItem: getIcon('ic_menu_item'),
  forwarder: getIcon('ic_forwarder'),
  operator: getIcon('ic_operator'),
  insurer: getIcon('ic_insurer'),
  insurerClientApplication: getIcon('ic_insurer_application'),
  loanClientApplications: getIcon('ic_loan_application'),
  project: getIcon('ic_project'),
  entries: getIcon('ic_entries'),
  userList: getIcon('ic_user_list'),
  userProfile: getIcon('ic_user_profile'),
  reports: <Summarize />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      { title: 'users', path: PATH_DASHBOARD.operator.root, icon: ICONS.userList },
      {
        title: 'Reports',
        icon: ICONS.reports,
        children: [
          {
            title: 'Risk Report',
            path: PATH_DASHBOARD.report.riskyUser,
            icon: ICONS.riskReport,
          },
          // { title: 'General settings', path: PATH_DASHBOARD.insurerRole.setting.general, icon: ICONS.generalSettings },
        ],
      },
      // { title: 'stocks', path: PATH_DASHBOARD.instrument.all, icon: ICONS.userList },
      // {
      //   title: 'forwarders',
      //   icon: ICONS.forwarder,
      //   path: '#1',
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.forwarder.root, icon: ICONS.userList },
      //     {
      //       title: 'entries',
      //       path: PATH_DASHBOARD.entries.root,
      //       icon: ICONS.entries,
      //     },
      //   ],
      // },
      // {
      //   title: 'insurer',
      //   icon: ICONS.insurer,
      //   path: '#1',
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.insurer.root, icon: ICONS.userList },
      //     {
      //       title: 'client applications',
      //       path: PATH_DASHBOARD.clientApplications.root,
      //       icon: ICONS.insurerClientApplication,
      //     },
      //   ],
      // },

      // // { title: 'operator', path: PATH_DASHBOARD.operator.root, icon: ICONS.operator },
      // {
      //   title: 'operator',
      //   path: '#1',
      //   icon: ICONS.operator,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.operator.root, icon: ICONS.userList },
      //     {
      //       title: 'projects',
      //       path: PATH_DASHBOARD.project.root,
      //       icon: ICONS.project,
      //     },
      //   ],
      // },
      // {
      //   title: 'banker',
      //   path: '#1',
      //   icon: ICONS.banking,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.banker.root, icon: ICONS.userList },
      //     {
      //       title: 'client loans',
      //       path: PATH_DASHBOARD.clientLoans.root,
      //       icon: ICONS.loanClientApplications,
      //     },
      //   ],
      // },
      // { title: 'entries', path: PATH_DASHBOARD.entries.root, icon: ICONS.user },
    ],
  },
  // {
  //   subheader: 'accounts',
  //   // items: [{ title: 'profile', path: PATH_DASHBOARD.user.account, icon: ICONS.user }],
  //   items: [{ title: 'profile', path: PATH_DASHBOARD.profile.root, icon: ICONS.userProfile }],
  // },
  // {
  //   subheader: 'chats',
  //   // items: [{ title: 'profile', path: PATH_DASHBOARD.user.account, icon: ICONS.user }],
  //   items: [{ title: 'chats', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat }],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2', path: '#2', disabled: true },
  //         {
  //           title: 'menu_level_2',
  //           path: '#3',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
