import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { RHFSelect, RHFSwitch, RHFTextField, RHFToggleButtonGroup } from '../../../../../components/hook-form';
import { dispatch } from '../../../../../redux/store';
import { getOperator } from '../../../../../redux/slices/operator';
import ChangePasswordDialog from './ChangePasswordDialog';
import axios from '../../../../../utils/axios';

const PersonalDetailFom = ({ isEdit, userType, manager, methods, currentUser }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false); // State for dialog
  const userList = useSelector((state) => state.operator.operatorData);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (userType === 'Client' && manager.role.rolename === 'admin') {
      dispatch(getOperator(manager._id));
    }
  }, [manager, userType]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangePassword = async (newPassword) => {
    // Dispatch change password action with the new password
    try {
      const response = await axios.put('/user/reset-password', { password: newPassword, userId: currentUser._id });
      enqueueSnackbar(response.data.message);
      handleCloseDialog();
    } catch (err) {
      console.log(err.message);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  // Conditionally render the JSX block based on the userType
  let formContent = null;
  if (userType.toLowerCase() !== 'client') {
    formContent = (
      <Box
        sx={{
          display: 'grid',
          columnGap: 2,
          rowGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
      >
        <RHFTextField
          disabled={isEdit}
          name="username"
          label="Username *"
          inputProps={{ style: { textTransform: 'lowercase' } }}
        />
        {isEdit ? (
          <>
            <RHFTextField
              name="password"
              label="Password *"
              type="password"
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="contained" onClick={handleOpenDialog}>
                      Change Password
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <ChangePasswordDialog open={open} onClose={handleCloseDialog} onSubmit={handleChangePassword} />
          </>
        ) : (
          <RHFTextField
            name="password"
            label="Password *"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        <RHFTextField name="firstname" label="First Name" />
        <RHFTextField name="lastname" label="Last Name" />
        <RHFTextField name="email" label="Email" />
        <RHFTextField name="phone" label="Phone" type="tel" maxLength={10} />
        <RHFTextField name="city" label="City" />
        <RHFTextField name="state" label="State" />
        <RHFTextField name="country" label="Country" />
      </Box>
    );
  } else {
    formContent = (
      <Box
        sx={{
          display: 'grid',
          columnGap: 2,
          rowGap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
      >
        <Box sx={{ display: 'grid', columnGap: 2, rowGap: 2, gridTemplateColumns: 'repeat(1,1fr)' }}>
          {manager.role.rolename === 'admin' && (
            <RHFSelect name="manager" label="Broker">
              <option value="" />
              {userList.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.username}
                </option>
              ))}
            </RHFSelect>
          )}
          <RHFTextField
            disabled={isEdit}
            name="username"
            label="Username *"
            inputProps={{ style: { textTransform: 'lowercase' } }}
          />
          {isEdit ? (
            <>
              <RHFTextField
                name="password"
                label="Password *"
                type="password"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button variant="contained" onClick={handleOpenDialog}>
                        Change Password
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <ChangePasswordDialog open={open} onClose={handleCloseDialog} onSubmit={handleChangePassword} />
            </>
          ) : (
            <RHFTextField
              name="password"
              label="Password *"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <RHFTextField name="firstname" label="First Name" />
          <RHFTextField name="lastname" label="Last Name" />
          {/* <RHFTextField name="email" label="Email" /> */}
          <RHFTextField name="phone" label="Phone" type="tel" maxLength={10} />
          <RHFTextField name="city" label="City" />

          {/* <RHFTextField name="state" label="State" /> */}
          {/* <RHFTextField name="country" label="Country" /> */}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ mr: 2, flex: 1 }}>Settlement Report Type</Typography>
            <RHFToggleButtonGroup
              name="defaultReportType"
              label="Settlement Report Type"
              color="primary"
              defaultValue={'excel'}
              sx={{ width: '100%', flex: 2 }}
              options={[
                { label: 'EXCEL', value: 'EXCEL' },
                { label: 'PDF', value: 'PDF' },
              ]}
            />
          </Box>
          <RHFSwitch
            name="isDemoAccount"
            label="Demo Account"
            labelPlacement="end"
            sx={{ py: 2, justifyContent: 'start' }}
          />
          <RHFSwitch
            name="allowMultiDeviceLogin"
            label="Allow Multi-Device Login"
            labelPlacement="end"
            sx={{ py: 2, justifyContent: 'start' }}
          />
          <RHFSwitch
            name="enablePattern"
            label="Secure Login Via Pattern"
            labelPlacement="end"
            sx={{ py: 2, justifyContent: 'start' }}
          />

          {methods.getValues().enablePattern && (
            <RHFTextField
              name="pattern"
              label="Pattern"
              sx={{ justifyContent: 'start' }}
              inputProps={{ maxLength: 4 }}
            />
          )}
        </Box>
      </Box>
    );
  }

  return formContent;
};

export default PersonalDetailFom;
