import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Visibility } from '@mui/icons-material';
import { TableRow, TableCell, IconButton, Tooltip } from '@mui/material';
import { fDateTime } from '../../../utils/formatTime';
import ActivityDetailLogModal from './ActivityDetailLogModal';

ActivityLogTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};

export default function ActivityLogTableRow({ row, selected }) {
  const [openModal, setOpenModal] = useState(false);
  const { type, message, createdAt, userId, creator, status, errormessage } = row;

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {/* <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell> */}

        <TableCell align="left">{type}</TableCell>

        <TableCell align="left">{status === 'fail' ? `${message}|(Error:${errormessage})` : message}</TableCell>
        <TableCell align="left">{`${creator?.username} (${creator?.role?.rolename})`}</TableCell>

        <TableCell align="left">{`${userId?.username} (${userId?.role?.rolename})`}</TableCell>

        <TableCell align="left">{fDateTime(createdAt)}</TableCell>
        <TableCell align="left" sx={{ color: status === 'fail' ? 'red' : 'green', textTransform: 'uppercase' }}>
          {status}
        </TableCell>

        <TableCell align="right">
          <Tooltip title="View Details">
            <IconButton onClick={handleOpenModal}>
              <Visibility />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      {/* Render the modal here */}
      <ActivityDetailLogModal open={openModal} onClose={handleCloseModal} log={row} />
    </>
  );
}
