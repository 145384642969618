/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Grid, FormControl, Checkbox, FormControlLabel, Menu, MenuItem, Paper } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilterList } from '@mui/icons-material';
import { RHFTextField } from '../../../components/hook-form';
import useAuth from '../../../hooks/useAuth';

const schema = yup.object().shape({
  description: yup.string().nullable(),
  createdBy: yup.string().nullable(),
  forUser: yup.string().nullable(),
  // Start Date Validation
  startDate: yup
    .date()
    .nullable()
    .transform((originalValue) => {
      // Transform empty string or invalid date to null
      const parsedDate = new Date(originalValue);
      return parsedDate instanceof Date && !isNaN(parsedDate) ? parsedDate : null;
    })
    .test('startDate-required', 'Start date is required if end date is specified', function checkDate(startDate) {
      const { endDate } = this.parent;
      if (endDate && !startDate) {
        return false; // Start date is required if end date is specified
      }
      return true;
    }),
  // End Date Validation
  endDate: yup
    .date()
    .nullable()
    .transform((originalValue) => {
      // Transform empty string or invalid date to null
      const parsedDate = new Date(originalValue);
      return parsedDate instanceof Date && !isNaN(parsedDate) ? parsedDate : null;
    })
    .test('endDate-required', 'End date is required if start date is specified', function checkDate(endDate) {
      const { startDate } = this.parent;
      if (startDate && !endDate) {
        return false; // End date is required if start date is specified
      }
      return true;
    })
    .min(yup.ref('startDate'), 'End date cannot be before start date'), // Check if endDate is after startDate
});
LogFilterToolbar.propTypes = {
  handleFilterName: PropTypes.func.isRequired,
};
export default function LogFilterToolbar({ handleFilterName }) {
  const [userRoles, setUserRoles] = useState({
    SuperAdmin: false,
    Admin: false,
    Broker: false,
    Client: false,
  });
  const [statuses, setStatuses] = useState({
    Success: false,
    Fail: false,
  });
  const [anchorElRoles, setAnchorElRoles] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      createdBy: '',
      forUser: '',
      startDate: '',
      endDate: '',
    },
  });
  const { user } = useAuth();
  const { handleSubmit, reset } = methods;
  const handleUserRolesChange = (event) => {
    setUserRoles({
      ...userRoles,
      [event.target.name]: event.target.checked,
    });
  };
  const handleStatusChange = (event) => {
    setStatuses({
      ...statuses,
      [event.target.name]: event.target.checked,
    });
  };
  const handleRoleMenuClick = (event) => setAnchorElRoles(event.currentTarget);
  const handleRoleMenuClose = () => setAnchorElRoles(null);
  const handleStatusMenuClick = (event) => setAnchorElStatus(event.currentTarget);
  const handleStatusMenuClose = () => setAnchorElStatus(null);
  const onSubmit = (data) => {
    const filters = {
      ...data,
      startDate: formatDate(data.startDate),
      endDate: formatDate(data.endDate),
      userRoles: Object.keys(userRoles)
        .filter((role) => userRoles[role])
        .map((role) => role.toLowerCase()),
      statuses: Object.keys(statuses)
        .filter((status) => statuses[status])
        .map((status) => status.toLowerCase()),
    };
    handleFilterName(filters);
    console.log(filters);
  };
  const handleReset = () => {
    reset();
    setUserRoles({ SuperAdmin: false, Admin: false, Broker: false, Client: false });
    setStatuses({ SUCCESS: false, FAIL: false });
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Ensure it's a valid date before formatting
    if (date instanceof Date && !isNaN(date)) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return ''; // Return empty string if invalid date
  }
  return (
    <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 2 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={1.6}>
                <RHFTextField name="description" label="DESCRIPTION" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={1.6}>
                <RHFTextField name="createdBy" label="CREATED BY" size="small" />
              </Grid>
              <Grid item xs={12} sm={4} md={1.6}>
                <RHFTextField name="forUser" label="FOR USER" size="small" />
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Stack direction="row" spacing={2}>
                  <RHFTextField
                    name="startDate"
                    label="START DATE"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                  <RHFTextField
                    name="endDate"
                    label="END DATE"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <Button
                      aria-controls="role-menu"
                      aria-haspopup="true"
                      onClick={handleRoleMenuClick}
                      startIcon={<FilterList />}
                      fullWidth
                      sx={{ padding: 1, fontSize: 14 }}
                    >
                      USER ROLES
                    </Button>
                    <Menu
                      id="role-menu"
                      anchorEl={anchorElRoles}
                      open={Boolean(anchorElRoles)}
                      onClose={handleRoleMenuClose}
                    >
                      {Object.keys(userRoles)
                        .filter((role) => {
                          if (role === 'SuperAdmin') return user.role.rolename === 'superAdmin';
                          if (role === 'Admin')
                            return user.role.rolename === 'admin' || user.role.rolename === 'superAdmin';
                          return true;
                        })
                        .map((role) => (
                          <MenuItem key={role}>
                            <FormControlLabel
                              control={
                                <Checkbox checked={userRoles[role]} onChange={handleUserRolesChange} name={role} />
                              }
                              label={role}
                            />
                          </MenuItem>
                        ))}
                    </Menu>
                  </FormControl>
                  <FormControl fullWidth>
                    <Button
                      aria-controls="status-menu"
                      aria-haspopup="true"
                      onClick={handleStatusMenuClick}
                      startIcon={<FilterList />}
                      fullWidth
                      sx={{ padding: 1, fontSize: 14 }}
                    >
                      STATUS
                    </Button>
                    <Menu
                      id="status-menu"
                      anchorEl={anchorElStatus}
                      open={Boolean(anchorElStatus)}
                      onClose={handleStatusMenuClose}
                    >
                      {Object.keys(statuses).map((status) => (
                        <MenuItem key={status}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={statuses[status]} onChange={handleStatusChange} name={status} />
                            }
                            label={status}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Button variant="contained" type="submit" sx={{ padding: 1, fontSize: 14 }}>
                APPLY FILTER
              </Button>
              <Button variant="outlined" color="inherit" onClick={handleReset} sx={{ padding: 1, fontSize: 14 }}>
                RESET
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
}
