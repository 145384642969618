import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TableRow, TableCell } from '@mui/material';

function ExchangeMargin({ config, type }) {
  const getTableRow = (category, header) => {
    const categoryConfig = config[category];
    if (!categoryConfig) return null;

    return (
      <TableRow key={category} sx={{ fontSize: '12px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
        <TableCell sx={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <Typography
            variant="body2"
            textTransform="uppercase"
            sx={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold' }}
          >
            {header}
          </Typography>
        </TableCell>
        <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          {categoryConfig.brokerageValue}
        </TableCell>
        <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          {categoryConfig.intradayExposure}
        </TableCell>
        <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
          {categoryConfig.holdingExposure}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {type === 'NSE' && (
        <>
          <TableRow key="NSE" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <TableCell rowSpan={2} sx={{ border: '1px solid rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
              <Typography variant="body2" textTransform="uppercase" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                NSE
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ fontSize: '12px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <TableCell sx={{ border: '1px solid rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
              <Typography variant="body2" textTransform="uppercase" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                ALL
              </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
              {config.brokerageValue !== 0 && config.brokerageValue !== '' ? `${+config?.brokerageValue / 1000}K` : 0}
              /1CR
            </TableCell>
            <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
              {config.intradayExposure}
            </TableCell>
            <TableCell sx={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
              {config.holdingExposure}
            </TableCell>
          </TableRow>
        </>
      )}
      {type === 'MCX' && (
        <>
          <TableRow key="MCX" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <TableCell rowSpan={5} sx={{ border: '1px solid rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
              <Typography variant="body2" textTransform="uppercase" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                MCX
              </Typography>
            </TableCell>
          </TableRow>
          {getTableRow('metal', 'Metal')}
          {getTableRow('miniMetal', 'MINI Metal')}
          {getTableRow('baseMetal', 'Base Metal')}
          {getTableRow('energy', 'Energy')}
        </>
      )}
      {type === 'COMEX' && (
        <>
          <TableRow key="COMEX" sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <TableCell rowSpan={6} sx={{ border: '1px solid rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
              <Typography variant="body2" textTransform="uppercase" sx={{ fontSize: '10px', fontWeight: 'bold' }}>
                COMEX
              </Typography>
            </TableCell>
          </TableRow>
          {getTableRow('gold', 'Gold')}
          {getTableRow('silver', 'Silver')}
          {getTableRow('copper', 'Copper')}
          {getTableRow('crude', 'Crude Oil')}
          {getTableRow('NG', 'Natural Gas')}
        </>
      )}
    </>
  );
}

ExchangeMargin.propTypes = {
  config: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default ExchangeMargin;
