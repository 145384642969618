/* eslint-disable react/jsx-no-undef */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Card, Typography, Box, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useEffect } from 'react';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from '../../../../utils/axios';
import useAuth from '../../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('OLD PASSWORD IS REQUIRED'),
    newPassword: Yup.string().min(6, 'PASSWORD MUST BE AT LEAST 6 CHARACTERS').required('NEW PASSWORD IS REQUIRED'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'CONFIRM PASSWORD AND NEW PASSWORD MUST MATCH')
      .required('CONFIRM PASSWORD IS REQUIRED'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    try {
      console.log('PAYLOAD', payload);
      const response = await axios.put('/user/change-password', payload);
      reset();
      enqueueSnackbar(response?.data?.message.toUpperCase());
    } catch (error) {
      enqueueSnackbar(error.message.toUpperCase(), { variant: 'error' }); // Displaying error message
    }
  };
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('md')); // Check if screen width is >= 900px

  // Prevent scrolling on mount
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scroll when component unmounts
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Ensure it takes the full viewport height
        p: isLaptop ? 0 : 2, // Adjust padding based on screen size
        overflow: 'hidden', // Ensure no scrolling
      }}
    >
      <Card
        sx={{
          p: isLaptop ? 2 : 4, // More padding for laptop
          maxWidth: isLaptop ? 1200 : 350, // Larger width for laptop, smaller for mobile
          width: '100%',
          boxShadow: isLaptop ? 6 : 3, // Different shadow for laptop
          marginBottom: 80,
          height: 'auto', // Let the height adjust based on content
        }}
      >
        <Typography
          variant={isLaptop ? 'h4' : 'h5'} // Larger font size for laptop
          gutterBottom
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
        >
          Change Password
        </Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField
              name="oldPassword"
              type="password"
              label="Old Password"
              InputLabelProps={{ style: { textTransform: 'uppercase' } }}
            />

            <RHFTextField
              name="newPassword"
              type="password"
              label="New Password"
              InputLabelProps={{ style: { textTransform: 'uppercase' } }}
            />

            <RHFTextField
              name="confirmNewPassword"
              type="password"
              label="Confirm New Password"
              InputLabelProps={{ style: { textTransform: 'uppercase' } }}
            />

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                mt: 2,
                alignSelf: 'center',
                textTransform: 'uppercase',
                width: isLaptop ? '50%' : '100%', // Adjust button width based on screen size
                backgroundColor: user.role.rolename === 'client' ? 'blue' : '',
              }}
            >
              Update Password
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Card>
    </Box>
  );
}
