import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, Typography, AppBar, Box, IconButton, TextField, Modal } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { FilterList, Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import Page from '../../../../components/Page';
import { getFilterableTradeHistory } from '../../../../redux/slices/trades';
import TradeHistoryRow from './TradeHistoryRow';
import TradeHistoryFilterCard from '../../../../pages/dashboard/operator/TradeHistoryFilterCard';

const TradeHistory = () => {
  const dispatch = useDispatch();
  const { ftradeHistory, fbookedPl, fbrokerage } = useSelector((state) => state?.trade);
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false); // Popup state

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSwipeChangeIndex = (index) => {
    setTabValue(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getFilterableTradeHistory());
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabValue]);

  const handleSearchIconClick = () => {
    setShowSearchBar((prev) => !prev);
  };

  const handleFilterIconClick = () => {
    setShowFilterPopup(true);
  };

  const handleClosePopup = () => {
    setShowFilterPopup(false);
  };

  const filteredTradeHistory = ftradeHistory.filter((trade) => {
    // Filter based on the selected tab and search term
    const matchesTab =
      (tabValue === 0 && trade.type === 'ORDER') ||
      (tabValue === 1 && trade.type === 'LIMIT') ||
      (tabValue === 2 && (trade.type === 'CLOSE' || trade.type === 'OPEN')) ||
      (tabValue !== 0 && tabValue === 1 && tabValue !== 2 && ['EXECUTED', 'CANCELLED'].includes(trade.type));

    const matchesSearch = searchTerm
      ? trade?.script?.tradingsymbol && trade?.script?.tradingsymbol.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    return matchesTab && matchesSearch;
  });

  // eslint-disable-next-line react/prop-types
  const HeaderItem = ({ label, value }) => (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', sm: 'row' }}
      justifyContent={'space-between'}
      flexGrow={1}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      sx={{ padding: 1, border: '0.5px solid black', borderRadius: '4px', marginBottom: { xs: 1, sm: 0 } }}
    >
      <Typography variant="body1" color={'GrayText'}>
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );

  return (
    <Page title="Portfolio" sx={{ px: 2 }}>
      <AppBar
        position="static"
        color="default"
        sx={{
          boxShadow: 'none',
          backgroundColor: '#f0f0f0',
          px: -2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('md')]: {
              gap: 0.5,
            },
          }}
        >
          <IconButton sx={{ color: 'blue', fontSize: '1rem', mx: 0.3 }} onClick={handleFilterIconClick}>
            <FilterList sx={{ fontSize: '2rem' }} />
          </IconButton>

          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{
              '& .MuiTab-root': {
                fontSize: '1rem',
                [theme.breakpoints.down('md')]: {
                  fontSize: '0.8rem',
                  mx: 0.9,
                },
              },
            }}
          >
            <Tab label="TRADES" value={0} />
            <Tab label="LIMITS" value={1} />
            <Tab label="DEALS" value={2} />
          </Tabs>

          <IconButton sx={{ color: 'blue', fontSize: '1rem', mx: 0.3 }} onClick={handleSearchIconClick}>
            <Search sx={{ fontSize: '2rem' }} />
          </IconButton>
        </Box>
      </AppBar>

      <Modal
        open={showFilterPopup}
        onClose={handleClosePopup}
        aria-labelledby="filter-popup-title"
        aria-describedby="filter-popup-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '60%', md: '40%' },
          }}
        >
          <TradeHistoryFilterCard onClose={handleClosePopup} />
        </Box>
      </Modal>

      {showSearchBar && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            backgroundColor: '#f5f5f5',
          }}
        >
          <TextField
            placeholder="Search Script"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              maxWidth: 600,
              '& .MuiInputBase-root': {
                borderRadius: '4px',
              },
            }}
          />
        </Box>
      )}
      <SwipeableViews index={tabValue} onChangeIndex={handleSwipeChangeIndex} style={{ minHeight: '100vh' }}>
        <Box role="tabpanel" hidden={tabValue !== 0}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            {filteredTradeHistory.length > 0 ? (
              <>
                {filteredTradeHistory.map((trade) => (
                  <TradeHistoryRow trade={trade} key={trade._id} />
                ))}

                {!showSearchBar && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row', md: 'row' },
                      width: '100%',
                      border: '0.5px solid black',
                      padding: { xs: 1, sm: 1.5, md: 2 },
                      borderRadius: '6px',
                      gap: { xs: 1, sm: 2 },
                    }}
                  >
                    <HeaderItem label={'BOOKED P/L'} value={fbookedPl?.toFixed(2)} />
                    <HeaderItem label={'BROKERAGE'} value={fbrokerage?.toFixed(2)} />
                    <HeaderItem label={'GROSS P/L'} value={(fbookedPl - fbrokerage)?.toFixed(2)} />
                  </Box>
                )}
              </>
            ) : (
              <Typography
                variant="h6"
                mt={2}
                ml={2}
                sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: 16 }}
              >
                NO TRADES
              </Typography>
            )}
          </Box>
        </Box>

        <Box role="tabpanel" hidden={tabValue !== 1}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            {filteredTradeHistory.length > 0 ? (
              filteredTradeHistory.map((trade) => <TradeHistoryRow trade={trade} key={trade._id} />)
            ) : (
              <Typography
                variant="h6"
                mt={2}
                ml={2}
                sx={{ diplay: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: 16 }}
              >
                NO LIMITS
              </Typography>
            )}
          </Box>
        </Box>

        <Box role="tabpanel" hidden={tabValue !== 2}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            {filteredTradeHistory.length > 0 ? (
              filteredTradeHistory.map((trade) => <TradeHistoryRow trade={trade} key={trade._id} />)
            ) : (
              <Typography
                variant="h6"
                mt={2}
                ml={2}
                sx={{ diplay: 'flex', justifyContent: 'center', textAlign: 'center', paddingTop: 16 }}
              >
                NO DEALS
              </Typography>
            )}
          </Box>
        </Box>
      </SwipeableViews>
    </Page>
  );
};

export default TradeHistory;
