import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  AppBar,
  Toolbar,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getFilterableTradeHistory } from '../../../redux/slices/trades';
import { dispatch } from '../../../redux/store';

// eslint-disable-next-line react/prop-types
const TradeHistoryFilterCard = ({ onClose }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sortOption, setSortOption] = useState('createdAt');
  const [nseChecked, setNseChecked] = useState(true);
  const [mcxChecked, setMcxChecked] = useState(true);
  const [sortingOrder, setSortingOrder] = useState('asc');
  const [dateError, setDateError] = useState('');
  const [segmentError, setSegmentError] = useState(false);
  const [apicallFail, setApiCallFail] = useState(false);

  const theme = createTheme({
    palette: {
      primary: { main: '#1976d2' },
      background: { paper: '#fff' },
    },
    typography: {
      fontSize: 14,
      '@media (max-width:600px)': { fontSize: 12 },
      '@media (min-width:1024px)': { fontSize: 16 },
    },
  });

  const handleApplyFilter = async () => {
    setDateError('');
    setApiCallFail(false);
    setSegmentError(false);

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setDateError('Both start and end dates are required.');
      return;
    }

    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const selectedStartDate = new Date(startDate);
    const selectedEndDate = new Date(endDate);

    if (startDate && endDate) {
      if (selectedStartDate < thirtyDaysAgo || selectedEndDate > today) {
        setDateError('Date range must be within the last 30 days.');
        return;
      }

      if (selectedEndDate < selectedStartDate) {
        setDateError('End date must be greater than start date.');
        return;
      }
    }

    if (nseChecked === false && mcxChecked === false) {
      setSegmentError(true);
      return; 
    }

    const finalParams = {
      sortKey: sortOption,
      sortOrder: sortingOrder,
      nseChecked: nseChecked || false,
      mcxChecked: mcxChecked || false,
    };

    if (startDate && endDate) {
      finalParams.startDate = startDate;
      finalParams.endDate = endDate;
    }

    // localStorage.setItem('tradeHistoryFilters', JSON.stringify(finalParams));
    let errorFlag = false;
    try {
      await dispatch(getFilterableTradeHistory(finalParams));
    } catch (error) {
      errorFlag = true;
      console.log(errorFlag);
    }
    if (errorFlag === true) {
      setApiCallFail(true);
      return;
    }
    onClose();
  };

  const handleResetFilter = () => {
    setStartDate('');
    setEndDate('');
    setSortOption('createdAt');
    setSortingOrder('asc');
    setNseChecked(true);
    setMcxChecked(true);
    setDateError('');
    setSegmentError(false);
    setApiCallFail(false);
    // localStorage.removeItem('tradeHistoryFilters');
  };

  const handleSegmentChange = (segmentSetter, segmentChecked) => {
    segmentSetter(segmentChecked);
    if (segmentChecked) {
      setSegmentError(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 3,
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 5,
          maxWidth: '800px',
          margin: 'auto',
        }}
      >
        <AppBar position="static" sx={{ marginBottom: 3 }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', fontSize: '1rem' }}>
              FILTER DATA
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              type="date"
              label="START DATE"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                if (dateError) setDateError('');
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type="date"
              label="END DATE"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                if (dateError) setDateError('');
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        {dateError && (
          <Typography color="error" sx={{ mb: 2, textTransform: 'uppercase' }}>
            {dateError}
          </Typography>
        )}

        <TextField
          select
          label="SORT BY"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          sx={{ mb: 2 }}
          fullWidth
          SelectProps={{ native: true }}
          variant="outlined"
        >
          <option value="createdAt">LAST UPDATED</option>
          <option value="script.tradingsymbol">A TO Z</option>
        </TextField>

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <Typography variant="subtitle1">Sorting Order</Typography>
          <Select value={sortingOrder} onChange={(e) => setSortingOrder(e.target.value)} fullWidth>
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          FILTER BY SEGMENTS
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox checked={nseChecked} onChange={(e) => handleSegmentChange(setNseChecked, e.target.checked)} />
            }
            label="NSE"
          />
          <FormControlLabel
            control={
              <Checkbox checked={mcxChecked} onChange={(e) => handleSegmentChange(setMcxChecked, e.target.checked)} />
            }
            label="MCX"
          />
        </Box>
        {segmentError && (
          <Typography
            color="error"
            sx={{ mb: 1, textTransform: 'uppercase', display: 'flex', textAlign: 'center', justifyContent: 'center' }}
          >
            At least one segment must be selected.
          </Typography>
        )}

        {apicallFail ? (
          <Typography
            color="error"
            sx={{ mb: 2, textTransform: 'uppercase', display: 'flex', justifyContent: 'center', fontSize: '0.65rem' }}
          >
            Unable to filter trades data, please contact support.
          </Typography>
        ) : (
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              fontSize: '0.65rem',
              textTransform: 'uppercase',
            }}
          >
            If a date range is not passed, the filter will apply only to this week's trades.
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleApplyFilter}>
            APPLY
          </Button>
          <Button variant="outlined" onClick={handleResetFilter}>
            RESET
          </Button>
          <Button variant="outlined" onClick={onClose}>
            CLOSE
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TradeHistoryFilterCard;
