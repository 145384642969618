/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, CardContent, Grid, Modal, Paper, IconButton } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Close, OpenInFullOutlined, PermIdentity } from '@mui/icons-material';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';
// import { Wrapper } from '../../../sections/@dashboard/Trade/watchlist/BuyScriptDrawer';

SummaryView.propTypes = {
  summary: PropTypes.object.isRequired,
  onActiveBuySellClick: PropTypes.func,
};

export default function SummaryView({ summary, onActiveBuySellClick }) {
  const mainKeys = ['your', 'total', 'admin'];
  const subKeys = ['grossProfitLoss', 'activeProfitLoss', 'profitLoss', 'brokerage'];
  const role = summary?.user?.role?.rolename;
  let modifiedMainKey = [];
  if (role === 'broker') {
    modifiedMainKey = ['broker', 'client', 'admin'];
  }
  if (role === 'admin') {
    modifiedMainKey = ['admin', 'broker', 'superAdmin'];
  }
  if (role === 'superAdmin') {
    modifiedMainKey = ['superAdmin', 'admin'];
    mainKeys.splice(-1, 1);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [currentMainKey, setCurrentMainKey] = useState(null);
  const [currentMainIndex, setCurrentMainIndex] = useState(0);

  const handleOpenModal = (index, mainKey) => {
    setCurrentMainKey(mainKey);
    setCurrentMainIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentMainKey(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {mainKeys.map((mainKey, i) => (
          <Grid item key={mainKey} xs={12} md={3}>
            <Card
              sx={{
                backgroundColor: i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
              }}
            >
              <CardContent
                sx={{
                  backgroundColor:
                    i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'left',
                    padding: 1,
                    alignItems: 'center',
                    position: 'relative',
                    backgroundColor:
                      i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems={'center'}>
                    <PermIdentity sx={{ mr: 1 }} />
                    <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
                      {modifiedMainKey[i]}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => handleOpenModal(i, mainKey)}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <OpenInFullOutlined />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor:
                      i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                  }}
                >
                  {subKeys.map((subKey) => (
                    <Box
                      key={subKey}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            subKey === 'brokerage'
                              ? 'text'
                              : fColorNumber(summary.NSE[subKey][mainKey] + summary.MCX[subKey][mainKey]),
                        }}
                      >
                        {fNumber(summary.NSE[subKey][mainKey] + summary.MCX[subKey][mainKey])}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {subKey === 'brokerage' && 'Brokerage'}
                        {subKey === 'profitLoss' && 'Booked P/L'}
                        {subKey === 'activeProfitLoss' && 'Active P/L'}
                        {subKey === 'grossProfitLoss' && 'Gross P/L'}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} md={3}>
          <Paper
            elevation={3}
            sx={{ p: 2, flex: 1, marginBottom: 2, backgroundColor: 'background.newneutral', cursor: 'pointer' }}
            onClick={onActiveBuySellClick}
          >
            <Typography variant="h6" sx={{ fontWeight: '500', cursor: 'pointer' }} gutterBottom>
              Active Buy
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h5">{fNumber(summary?.activeBuy || 0)}</Typography>
              <Box>
                <Typography variant="body1">NSE: {fNumber(summary?.NSE?.activeBuy || 0)}</Typography>
                <Typography variant="body1">MCX: {fNumber(summary?.MCX?.activeBuy || 0)}</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{ p: 2, flex: 1, backgroundColor: 'background.newneutral', cursor: 'pointer' }}
            onClick={onActiveBuySellClick}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: '500', cursor: 'pointer' }}
              gutterBottom
              onClick={onActiveBuySellClick}
            >
              Active Sell
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h5">{fNumber(summary?.activeSell || 0)}</Typography>
              <Box>
                <Typography variant="body1">NSE: {fNumber(summary?.NSE?.activeSell || 0)}</Typography>
                <Typography variant="body1">MCX: {fNumber(summary?.MCX?.activeSell || 0)}</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Custom Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        {/* <Box> */}

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', md: 700 },
            backgroundColor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3, // Margin bottom to separate from content below
            }}
          >
            <Typography variant="h4" textTransform={'uppercase'}>
              {modifiedMainKey[currentMainIndex]}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          {/* Content Section */}
          <Grid container spacing={{ md: '40px', xs: '16px' }} padding={0} boxSizing={'border-box'}>
            {subKeys.map((subKey) => (
              <Grid item xs={12} sm={6} key={subKey}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    backgroundColor: 'background.newneutral',
                    gap: 3,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: 24, lineHeight: 1.35, color: 'gray' }} gutterBottom>
                    {subKey === 'brokerage' && 'Brokerage'}
                    {subKey === 'profitLoss' && 'Booked P/L'}
                    {subKey === 'activeProfitLoss' && 'Active P/L'}
                    {subKey === 'grossProfitLoss' && 'Gross P/L'}
                  </Typography>
                  <Box
                    sx={{
                      gap: 1,
                      display: 'flex',
                      flexDirection: { sm: 'row', md: 'column' },
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: { md: 40, xs: 24 },
                        lineHeight: 1.35,
                        color: fColorNumber(summary.NSE[subKey][currentMainKey] + summary.MCX[subKey][currentMainKey]),
                      }}
                      gutterBottom
                    >
                      {fNumber(
                        Number.parseInt(summary.NSE[subKey][currentMainKey] + summary.MCX[subKey][currentMainKey], 10)
                      )}
                    </Typography>
                    <Box display="flex" flexDirection={'column'} justifyContent="space-between" sx={{ mt: 1, gap: 1 }}>
                      <Box display="flex" flexDirection="row" gap={2}>
                        <Typography variant="body1" color={'#747474'}>
                          NSE
                        </Typography>
                        <Typography variant="body1" color={fColorNumber(summary.NSE[subKey][currentMainKey])}>
                          {fNumber(Number.parseInt(summary.NSE[subKey][currentMainKey], 10))}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="row" gap={2}>
                        <Typography variant="body1" color={'#747474'}>
                          MCX
                        </Typography>
                        <Typography variant="body1" color={fColorNumber(summary.MCX[subKey][currentMainKey])}>
                          {fNumber(Number.parseInt(summary.MCX[subKey][currentMainKey], 10))}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}
