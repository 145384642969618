/* eslint-disable no-return-assign */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  balance: 0,
  bookedPl: 0,
  brokerage: 0,
  blockedMargin: 0,
  showAccountDetails: true,
  watchlist: {},
  userTrades: {},
  tradeHistory: [],
  segementlist: [],
  subscribedInstruments: [],
  tradinViewQuotesData: {},
  tradingViewPreviousQuote: {},
  quotesData: {},
  previousQuote: {},
  instrumentList: [],
  instrumentData: {},
  ftradeHistory: [],
  fbalance: 0,
  fbookedPl: 0,
  fbrokerage: 0,
  fblockedMargin: 0,
  fpriceChangeConfig: {},
};

const slice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setShowAccountDetails(state, action) {
      state.showAccountDetails = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER'S Watchlist scripts
    getWatchlistSuccess(state, action) {
      if (action.payload.loading) {
        state.isLoading = false;
      }
      state.watchlist = action.payload;
    },

    addToWatchListSuccess(state, action) {
      state.isLoading = false;
      state.watchlist = action.payload;
      // state.watchlist = {
      //   ...state.watchlist,
      //   [action.payload.exchange.name]: [...(state.watchlist[action.payload.exchange.name] || []), action.payload],
      // };
    },
    // GET Quote Details

    getQuoteSuccess(state, action) {
      // state.isLoading = false;
      // const { watchlist } = state;
      // if (!watchlist) {
      //   return (state.quotesData = {});
      // }
      // const allScripts = [...Object.values(watchlist)].map((data) => data.instrument_token);
      // state.previousQuote = JSON.parse(JSON.stringify(state.quotesData));
      // action.payload.forEach((row) => {
      //   if (allScripts.some((token) => row.instrument_token === token)) {
      //     state.quotesData[row.instrument_token] = row;
      //   }
      // });
      action.payload.forEach((row) => {
        // eslint-disable-next-line no-prototype-builtins
        state.quotesData[row.instrument_token] = row;
      });
    },

    getTradeHistorySuccess(state, action) {
      state.isLoading = false;
      const { trades, balance, bookedPL, brokerage, priceChangeConfig, blockedMargin } = action.payload;
      state.tradeHistory = trades;
      state.balance = balance;
      state.bookedPl = bookedPL;
      state.brokerage = brokerage;
      state.blockedMargin = blockedMargin;
      state.priceChangeConfig = priceChangeConfig;
    },

    getfilteredTradeHistorySuccess(state, action) {
      state.isLoading = false;
      const { trades, balance, totalProfit, totalBrokerage, priceChangeConfig, blockedMargin } = action.payload;
      state.ftradeHistory = trades;
      state.fbalance = balance;
      state.fbookedPl = totalProfit;
      state.fbrokerage = totalBrokerage;
      state.fblockedMargin = blockedMargin;
      state.fpriceChangeConfig = priceChangeConfig;
    },

    getSegementListSuccess(state, action) {
      state.isLoading = false;
      state.segementlist = action.payload;
    },

    // GET Instrument Success
    getInstrumentSuccess(state, action) {
      state.isLoading = false;
      state.instrumentData = action.payload;
    },

    // GET TradingView Quote Success
    getTradingViewQuoteSuccess(state, action) {
      state.isLoading = false;
      state.tradingViewPreviousQuote = JSON.parse(JSON.stringify(state.tradinViewQuotesData));
      const row = action.payload;
      // if (row?.p[1]?.v?.trade_loaded) {
      //   state.tradinViewQuotesData[row.p[1].n] = row.p[1].v;
      // } else {
      // state.tradinViewQuotesData[row.p[1].n] = { ...state.tradinViewQuotesData[row.p[1].n], ...row.p[1].v };
      // }
      // console.log('ROW', row, state.tradinViewQuotesData[row.pid]);
      state.tradinViewQuotesData[row.pid] = { ...state.tradinViewQuotesData[row.pid], ...row };
    },

    getUserTradeSuccess(state, action) {
      state.isLoading = false;
      const { trades, balance, bookedPL, brokerage, priceChangeConfig, blockedMargin } = action.payload;
      state.userTrades = trades;
      state.balance = balance;
      state.bookedPl = bookedPL;
      state.brokerage = brokerage;
      state.blockedMargin = blockedMargin;
      state.priceChangeConfig = priceChangeConfig;
    },

    canelPendingTradeSuccess(state) {
      state.isLoading = false;
    },

    setSubscribedScript(state, action) {
      state.subscribedInstruments = action.payload;
    },
    // // GET NFO SUCCESS
    // getNfoSuccess(state, action) {
    //   state.isLoading = false;
    //   state.nfolist = action.payload;
    // },

    // // GET MCX SUCCESS
    // getMcxSuccess(state, action) {
    //   state.isLoading = false;
    //   state.mcxlist = action.payload;
    // },
    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;
      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },
    // GET CONVERSATIONS SUCCESS
    getConversationsSuccess(state, action) {
      state.allConversations = action.payload;
      state.isLoading = false;
    },

    // GET CONVERSATIONS SUCCESS
    searchConversationsSuccess(state, action) {
      state.searchConversations = action.payload;
      state.isLoading = false;
    },

    // POST MESSAGE SUCCESS
    postConversationSuccess(state) {
      state.isLoading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      state.isLoading = false;
      state.chatConversations = action.payload;
      let filterData = [];
      filterData = state.allConversations.filter((object1) =>
        action.payload.some((object2) => object1.conversation === object2.conversation)
      );
      filterData[0].chatConversations = action.payload;
    },

    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversationData = action.payload;
      state?.chatConversations.unshift(conversationData);
    },

    // ON LAST MESSAGE
    onLastMessage(state, action) {
      const filterConversationData = state.allConversations
        .filter((data) => data?.conversation === action.payload.conversation)
        .map((data) => data);
      filterConversationData[0].msg_text = action.payload.msg_text;
      filterConversationData[0].seen = false;
      filterConversationData[0].time = new Date();
      const objectReplace = state.allConversations.map((data) =>
        action.payload.conversation === data?.conversation ? filterConversationData : data
      );
      state.filterConversationData = objectReplace;
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecipients,
  onSendMessage,
  onLastMessage,
  resetActiveConversation,
  getQuoteSuccess,
  getWatchlistSuccess,
  getInstrumentSuccess,
  addToWatchListSuccess,
  getTradingViewQuoteSuccess,
  getSegementListSuccess,
  getUserTradeSuccess,
  getTradeHistorySuccess,
  getfilteredTradeHistorySuccess,
  setShowAccountDetails,
  startLoading: startTradeLoading,
  stopLoading: stopTradeLoading,
  setSubscribedScript,
  // getNfoSuccess,
  // getMcxSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/contacts');
      dispatch(slice.actions.getContactsSuccess(response.data.contacts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSegementList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/segement/all`);
      dispatch(getSegementListSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.data));
      throw error.data;
    }
  };
}

export function getInstrumentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get(
      //   `/script/all?${exchange ? `exchange=${exchange}` : ''}&page=${page}&limit=${rowsPerPage}${
      //     search ? `&search=${search}` : ''
      //   }`
      // );
      const response = await axios.get('script/all');
      dispatch(getInstrumentSuccess(response.data?.data));
      // if (exchange === 'NFO') {
      //   dispatch(getNfoSuccess(response.data.data));
      // } else {
      //   dispatch(getMcxSuccess(response.data.data));
      // }
    } catch (error) {
      dispatch(slice.actions.hasError(error.data));
      throw error;
    }
  };
}

export function getWatchList(loading = true) {
  return async () => {
    if (loading) {
      dispatch(slice.actions.startLoading());
    }
    try {
      // const response = await axios.get('/user/watchlist');
      const data = localStorage.getItem('watchlist');
      const parsedData = JSON.parse(data);
      dispatch(getWatchlistSuccess({ ...parsedData, loading }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addToWatchList(script) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const updatedWatchlist = JSON.parse(localStorage.getItem('watchlist'));
      // If no watchlist exists in local storage, create one
      if (Object.keys(updatedWatchlist).length === 0) {
        updatedWatchlist[script.exchange.name] = [];
      }

      // Insert data exchange-wise
      if (!updatedWatchlist[script.exchange.name]) {
        updatedWatchlist[script.exchange.name] = [];
      }
      updatedWatchlist[script.exchange.name].push(script);

      // Update watchlist state and local storage
      localStorage.setItem('watchlist', JSON.stringify(updatedWatchlist));
      dispatch(addToWatchListSuccess(updatedWatchlist));

      // Remove the added script from filteredScripts
      // const updatedFilteredScripts = filteredScripts.filter((s) => s._id !== script._id);
      //   const payload = { exchange, instrumentToken };
      //   const response = await axios.post('/user/watchlist', payload);
      //   dispatch(addToWatchListSuccess(response.data.watchlist));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPortfolio() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/trade/portfolio');
      dispatch(getUserTradeSuccess(response.data.data));
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw err.data;
    }
  };
}

export function getTradeHistory() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/trade/history');
      dispatch(getTradeHistorySuccess(response.data.data));
    } catch (err) {
      dispatch(slice.actions.hasError(err));
    }
  };
}

// eslint-disable-next-line no-unused-vars
export function getFilterableTradeHistory(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response;
      if (params) {
        // Use the updated parameter names to match Node.js expectations
        const { startDate, endDate, sortKey, sortOrder, nseChecked, mcxChecked } = params; // Change to 'sortKey' and 'sortOrder'

        const finalParams = {
          sortKey,
          sortOrder,
          nseChecked: nseChecked || false,
          mcxChecked: mcxChecked || false,
        };
        if(startDate && endDate){
          finalParams.startDate = startDate ;
          finalParams.endDate = endDate ;
        }
        const parameter = new URLSearchParams(finalParams);
        response = await axios.get(`/trade/only-history?${parameter}`);
      } else {
        response = await axios.get(`/trade/only-history`);
      }
      dispatch(getfilteredTradeHistorySuccess(response.data.data));
    } catch (err) {
      dispatch(slice.actions.hasError(err));
      throw err; 
    }
  };
}

export function cancelTrade(payload) {
  // eslint-disable-next-line consistent-return
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/trade/cancel-pending-trade/${payload.tradeId}`);
      // dispatch(getTradeHistorySuccess(response.data.data));
      if (!payload?.isManualTrade) {
        dispatch(getUserPortfolio());
      }
      return response.data;
    } catch (err) {
      dispatch(slice.actions.hasError(err));
    }
  };
}

// export function addPendingTrade(payload){
//   return async()=>{
//     dispatch(slice.actions.startLoading());
//     try{
//       const response = await axios.get('/trade/')
//     }catch(error){throw error;}
//   }
// }

export function getConversation(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/conversation', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET ALL CONVERSATIONS

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/conversations');
      const { status, conversations, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationsSuccess(conversations));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// SEARCH CONVERSATIONS

export function getSearchConversations(value) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/conversations?search=${value}`);
      const { status, conversations, message } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.searchConversationsSuccess(conversations));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// POST SEND MESSAGE

export function postSendMessage(payload) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/message', payload);
      const { status, message } = response;
      if (status === 200) {
        dispatch(slice.actions.postConversationSuccess());
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET CHAT

export function getChat(conversationKey) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/chats/${conversationKey}`);
      const { status, message, chats } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationSuccess(chats));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// PUT MAKE AS A SEEN

export function putMakeSeenChat(seenId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/make-as-seen/${seenId}`);
      const { status, message, chats } = response?.data;
      if (status === 200) {
        dispatch(slice.actions.getConversationSuccess(chats));
      } else {
        dispatch(slice.actions.hasError(message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
